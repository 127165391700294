import React from "react";

export default function DashboardFooter() {
  return (
    <div>
      <footer class="footer text-center">
        {new Date().getFullYear()} &copy; Bitto Kazi
      </footer>
    </div>
  );
}
